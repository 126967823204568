import Script from "next/script";

// Used by Next.js Image component.
// Needed to support old versions of Chrome, and Safari.
import "core-js/actual/string/match-all";

// Used by `use-resize-observer`.
// `ResizeObserver` may not be available, and will require a polyfill.
import { ResizeObserver } from "@juggle/resize-observer";

import * as Sentry from "@sentry/nextjs";

import { ApolloProvider } from "@apollo/client";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

import FlagProvider from "@/lib/unleash";

import { cache, createClient, persistor } from "@/lib/apollo/client";
import { AuthReadyProvider } from "@/lib/auth/useAuthReady";
import { UserProvider } from "@/lib/auth/useUser";
import { CartProvider } from "@/lib/cart";
import { auth } from "@/lib/firebase/auth";
import { loadErrorMessages } from "@apollo/client/dev";
import "./styles.css";

import { BlankLayout } from "@/layouts/BlankLayout";

// biome-ignore lint/correctness/noUndeclaredDependencies: mapbox import is weird
import "mapbox-gl/dist/mapbox-gl.css";
import "@/i18n";
import "@/styles/global.css";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { A2HSProvider } from "@/lib/hooks/useA2HS";

loadErrorMessages();
if (typeof window !== "undefined" && !window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

const client = createClient(cache);

auth.onAuthStateChanged((user) => {
    if (user) {
        Sentry.setUser({ id: user.uid, email: user.email ?? undefined });
    } else {
        Sentry.setUser(null);
        client.resetStore();
    }
});

// Helper function to track network status using navigator.onLine
// Add network status Sentry's breadcrumbs and custom tags
// See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
const updateNetworkStatusInSentry = () => {
    const status = navigator.onLine ? "Online" : "Offline";

    // Add breadcrumb for historical tracking
    Sentry.addBreadcrumb({
        category: "network",
        message: `Current Network Status ${status}`,
        level: "info",
    });

    // Set custom tag for network_status, this should be current, more accurate network state of an Event
    // Tags also allow filtering directly in Sentry.
    Sentry.setTag("network_status", status);
};

const EnjinPelanggan = ({ Component, pageProps, err }): JSX.Element => {
    const router = useRouter();
    const Layout = Component.Layout || BlankLayout;

    // Track networkStatus in Sentry using navigator.onLine property
    useEffect(() => {
        updateNetworkStatusInSentry();

        window.addEventListener("online", updateNetworkStatusInSentry);
        window.addEventListener("offline", updateNetworkStatusInSentry);

        return () => {
            window.removeEventListener("online", updateNetworkStatusInSentry);
            window.removeEventListener("offline", updateNetworkStatusInSentry);
        };
    }, []);

    useEffect(() => {
        router.events.on("routeChangeComplete", () => {
            window.scrollTo(0, 0);
        });
    }, [router]);

    useEffect(() => {
        const init = async (): Promise<void> => {
            try {
                persistor?.restore();
            } catch (error) {
                const customError = new Error(error.message);
                customError.name = "ApolloStoreError";
                Sentry.captureException(customError, (ctx) =>
                    ctx.setExtras({
                        error: error,
                    }),
                );
            }
        };

        if (typeof window !== "undefined") {
            init().catch(console.error);
        }
    }, []);

    return (
        <AuthReadyProvider>
            <UserProvider>
                <ApolloProvider client={client}>
                    <Head>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
                        />
                        <meta
                            key="enjin-version"
                            property="enjin-version"
                            content={`enjin-pelanggan/${
                                process.env.NEXT_PUBLIC_ENJIN_VERSION ||
                                "unknown"
                            }`}
                        />
                        {process.env.DISABLE_INDEXING ? (
                            <meta name="robots" content="noindex, nofollow" />
                        ) : null}
                    </Head>

                    <Script
                        src="https://static.cloudflareinsights.com/beacon.min.js"
                        data-cf-beacon='{"token": "c329a1da4464478caf12e270e41c70b0"}'
                    />
                    <Script src="https://checkout.razorpay.com/v1/checkout.js" />

                    <FlagProvider>
                        <CartProvider>
                            <A2HSProvider>
                                <Layout>
                                    {/* `err` is a workaround for https://github.com/vercel/next.js/issues/8592 */}
                                    <Component {...pageProps} err={err} />
                                    <ToastContainer
                                        hideProgressBar
                                        position="top-center"
                                        theme="dark"
                                        transition={Slide}
                                    />
                                </Layout>
                            </A2HSProvider>
                        </CartProvider>
                    </FlagProvider>
                </ApolloProvider>
            </UserProvider>
        </AuthReadyProvider>
    );
};

export default EnjinPelanggan;
