import { TZDate, tz } from "@date-fns/tz";
import { startOfDay } from "date-fns";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";

export const startOfDayAtTz = (d: Date, tz: string): Date =>
    fromZonedTime(startOfDay(d), tz);

export const startOfTodayAtTz = (tz: string): Date =>
    startOfDayAtTz(new Date(), tz);

export const tzKL = "+08:00";
export const inKL = { in: tz(tzKL) };

const fmtDt = (dt: string | Date, fmt: string, tz: string): string =>
    formatInTimeZone(dt, tz, fmt);

export const fmtDtKL = (dt: string | Date, fmt: string): string =>
    fmtDt(dt, fmt, tzKL);

/**
 * This is in contrast to `new TZDate(date, tz)` which takes the `date` and gives the same moment in
 * time in the given `tz`. This function will instead override the timezone in given `date` without
 * modifying the time values.
 */
export const overrideDateTimezone = (date: Date, tz: string): TZDate =>
    new TZDate(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
        tz,
    );

/**
 * This is in contrast to `new Date(date)` which if the `date` is `TZDate` in different timezone
 * than the device, will give the same moment in time in the device timezone. This function will
 * instead remove the timezone from the `tzDate` without modifying the time values.
 */
export const stripDateTimezone = (date: Date): Date =>
    new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
    );
