import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.tz.setDefault("Asia/Kuala_Lumpur");

// This utc fix must go in after the other extends and not before
const utcFix: dayjs.PluginFunc = (_, dayjsClass) => {
    dayjsClass.prototype.valueOf = function () {
        const addedOffset = !this.$utils().u(this.$offset)
            ? this.$offset +
              (this.$x.$localOffset || this.$d.getTimezoneOffset())
            : 0;
        return this.$d.valueOf() - addedOffset * 60 * 1000;
    };
};
dayjs.extend(utcFix);

export default dayjs;

/* 
The function is designed to replace the time zone of the input datetime 
to "Asia/Kuala_Lumpur" time zone without changing the datetime.
*/
export const centreTZ = (date: dayjs.Dayjs): dayjs.Dayjs => {
    const stripTZDate = date.format("YYYY-MM-DD HH:mm");
    const parseTZDate = dayjs.tz(
        stripTZDate,
        "YYYY-MM-DD HH:mm",
        "Asia/Kuala_Lumpur",
    );
    return parseTZDate;
};

/* 
The function is designed to replace the time zone of the input datetime 
to local time zone without changing the datetime.
*/
export const deviceTZ = (date: dayjs.Dayjs): dayjs.Dayjs => {
    const stripTZDate = date.format("YYYY-MM-DD HH:mm");
    const parseTZDate = dayjs(stripTZDate, "YYYY-MM-DD HH:mm");
    return parseTZDate;
};
